import React from "react";
import { graphql } from "gatsby";
import SinglePageBanner from "../components/SinglePageBanner";
import SimpleContent from "../components/SinglePage/SimpleContent";
import ImageBox from "../components/ImageBox";
import Gallery from "../components/Gallery";
import PrivateGuides from "../components/PrivateGuides";
import RelatedExperiences from "../components/RelatedExperiences";

const Experience = (props) => {
  const modules = props.modules.experiencesContent;  

  const components = {
    SinglePageBanner,
    SimpleContent,
    ImageBox,
    Gallery,
    PrivateGuides,
    RelatedExperiences
  }

  return (
    <>
      {modules
        ? modules.filter((module) => !module?.hideSection || module?.hideSection === "no").map((module, i) => {
          const moduleName = module.__typename.replace(
            "WpExperience_Experiences_ExperiencesContent_",
            ""
          );
          return (
            components[moduleName] &&
            React.createElement(components[moduleName], {
              key: i,
              module,
              i,
            })
          );
        })
        : null}
    </>
  )
};

export default Experience;
export const ExperienceFragment = graphql`
  fragment ExperienceFragment on WpExperience {
    experiences {
      experiencesContent {
        __typename
        ...SinglePageExperienceSingleBannerFragment
        ...ExperienceSingleSimpleContentFragment
        ...ExperienceSingleImageBoxFragment
        ...ExperienceSingleGalleryFragment
        ...PrivateGuidesFragment
        ...RelatedExperiencesFragment
      }
    }
  }
`;

