import React from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PrivateGuides = ({ module }) => {
  return (
    <section
      className={`discover-our-stories  bg-gray-200 lg:py-100 py-50${
        module.extraClass ? ` ${module.extraClass}` : ""
      }`}
    >
      <div className="container-fluid">
        <div className="section-title fade-ani text-center">
          {module?.heading && (
            <div className="title-black">
              <h3>{module.heading}</h3>
            </div>
          )}
          {module?.description && (
            <div className="content">{parse(module.description)}</div>
          )}
        </div>
        <div className="flex flex-wrap fade-img lg:mx-minus-15 lg:pt-50 pt-25 lgscreen:space-y-10">
          {module?.guide?.length > 0 &&
            module.guide.map((item, itemIndex) => (
              <div
                className="lg:w-6/12 w-full lg:px-15 px-0 fade-img"
                key={itemIndex.toString()}
              >
                <div className="discover-bx">
                  <GatsbyImage
                    image={getImage(item?.image)}
                    alt={item?.image?.altText || ""}
                  />
                  <div className="discover-bx-info lg:pt-30 pt-20 lg:pr-50 pr-0">
                    {item?.name && (
                      <h6 className="text-black-200 text-24">{item.name}</h6>
                    )}
                    {item?.description && (
                      <div className="content textsm">
                        {parse(item.description)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default PrivateGuides;
export const PrivateGuidesFragment = graphql`
  fragment PrivateGuidesFragment on WpExperience_Experiences_ExperiencesContent_PrivateGuides {
    id
    hideSection
    heading
    description
    guide {
      description
      name
      image {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
      }
    }
  }
`;